<template>
  <div>
    <div
      v-if="loading"
      class="spinner__container d-flex align-center justify-center"
    >
      <v-card :width="600" :max-width="400" :elevation="0">
        <v-card-text class="d-flex justify-center">
          <v-progress-circular indeterminate />
        </v-card-text>
      </v-card>
    </div>
    <div
      v-else-if="!loading && pageFetchingError"
      style="width: fit-content"
      class="mx-auto pb-15"
    >
      <Error />
    </div>
    <recursion
      v-else-if="!pageFetchingError && content"
      :content="content"
      :componentName="
        content.props.component
          ? content.props.component
          : content.componentName
      "
    />
    <div v-else class="pa-5"> На странице нет контента </div>
  </div>
</template>

<script>
import Error from '@/view/pages/error/Error-1.vue'
import axios from '@/core/services/api.service'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('blogs')
const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'Blog',
  metaInfo() {
    if (this.currentBlog) {
      const { seo_title, seo_description, seo_key, photo } = this.currentBlog
      const capitalizeTitle =
        seo_title.substring(0, 1).toUpperCase() + seo_title.substring(1)
      return {
        title: capitalizeTitle,
        meta: [
          { name: 'keywords', content: seo_key },
          { name: 'description', content: seo_description },
          { property: 'og:locale', content: this.localeLang },
          { property: 'og:title', content: seo_title },
          { property: 'og:description', content: seo_description },
          { property: 'og:url', content: window.location.origin + this.$route.fullPath },
          { property: 'og:site_name', content: 'Tumar Art Group' },
          { property: 'og:image', content: photo },
          { property: 'og:image:secure_url', content: photo },
        ],
      }
    }
  },
  components: {
    Error,
  },
  data() {
    return {
      pageFetchingError: false,
      loading: true,
      currentBlog: null,
    }
  },
  computed: {
    ...mapState(['localeLang']),
    content() {
      return this.currentBlog && this.currentBlog.content.content[0]
    },
  },
  methods: {
    ...mapActions(['fetchBlogByUrl']),
  },
  async created() {
    try {
      const res = await this.fetchBlogByUrl(this.$route.params.url)
      this.currentBlog = res
      this.currentBlog.content.content = JSON.parse(res.content.content)
    } catch {
      this.pageFetchingError = true
    }

    this.loading = false
  },
}
</script>

<style scoped>
.spinner__container {
  height: 500px;
}
</style>
