<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex justify-center error__container">
      <!-- begin:: Content -->
      <div class="error__content">
        <h1
          class="font-size-sm-100 font-weight-boldest"
          style="font-size: 150px"
        >
          404
        </h1>
        <p class="main__error-text">Oops, This Page Not Found!</p>
        <p class="second__error-text">The link may not be available to you</p>
        <p class="therd__error-text">or the page may have been removed.</p>
        <button @click="$router.push({ path: '/' })" class="error__button">GO BACK HOME</button>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error-1',
  data() {
    return {
      baseUrl: '',
    }
  },
  // mounted() {},
  computed: {
    backgroundImage() {
      return this.baseurl + 'media/error/bg1.jpg'
    },
  },
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
  },
}
</script>

<style lang="scss" scoped>
.error__content {
  h1 {
    text-align: center;
    color: #000;
  }
  display: flex;
  flex-direction: column;
  align-items: center; 
  background-color: transparent;
  margin-top: 100px;
  width: fit-content;
  padding: 0px 20px;
  .main__error-text {
    font-size: xxx-large;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  .second__error-text {
    font-size: x-large;
    line-height: normal;
    font-weight: 300;
    text-align: center;
  }
  .therd__error-text {
    font-size: large;
    line-height: normal;
    font-weight: 900;
    text-align: center;
  }
  .error__button{
    margin: 30px 0px;
    padding: 2px 10px;
    background: #000;
    color: white;
    width: 100%; // Сделает кнопку широкой
    max-width: 150px;
  }
}
.error__container {
  padding: 100px 0px;
}
@media only screen and (max-width: 425px){
    .main__error-text{
        font-size: xx-large !important;
    }
}
</style>
